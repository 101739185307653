import React from "react";

const AmountPay = ({ amount }) => {
  return (
    <div className="total">
      <div className="row">
        <div className="col-7 text-left"><p>Monto a pagar</p></div>
        <div className="col-5 text-center"><p>${new Intl.NumberFormat("de-DE").format(amount)}</p></div>
      </div>
    </div>
  );
};

export default AmountPay;
