import React from 'react';
import BoxAgreement from '../../components/Agreement/Box/Box';
import BoxNotification from '../../components/Notification/BoxNotifications';
import NameUser from '../../components/NameUser/NameUser';
import bannerMP from "./../../assets/image/bg.png";
import Layout from '../Cliente/Layout/Layout';

const Home = () => {
    return (
        <Layout bg={'bg-fluid-bottom'}>
            <NameUser st_medida_class="d-block d-md-none" />
            <div className="row ml-md-5 mr-md-5">
                <div className="col-12 col-md-12">
                    <BoxAgreement />
                </div>
                <div className="col-xs-12 order-first order-md-last col-md-12">
                    <BoxNotification />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <img src={bannerMP} className="img-fluid" width="100%" alt="" />
                </div>
            </div>
        </Layout>
    )
}

export default Home;