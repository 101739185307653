import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './Pages/Login';
import ServContratados from './Pages/Servicios/ServiciosContratados';
import CartolaDePago from './Pages/Cartola/CartolaPago';
import MisDatos from './Pages/MisDatos';
import PagoDetalle from './Pages/PagoDetalle/Detalle';
import CambiarContrasena from './Pages/CambiarContrasena';
import PreguntasFrecuentes from './Pages/PreguntasFrecuentes';
import Contacto from './Pages/Contacto';
import Postergacion from './Pages/Postergacion';
import End from './Pages/End';
import NotFound from '../pages/404/NotFound';
import ListPie from './Pages/Servicios/ListPie';
import Header from './../components/Header/';
import Footer from './../components/Footer/';

import './Assets/css/main.scss';
import './Assets/css/sideBarMenu.scss';
import Layout from '../pago-rapido/Layout';

const renderContent = (props) => {
    const Path = props.match.path;
    return (
            <Switch>
                <Route exact path={Path + "/"} component={() => (<><Layout><Header /><Login /><Footer /></Layout></>)} />
                <Route exact path={Path + "/servicios-contratados/"} component={ServContratados} />
                <Route exact path={Path + "/pago-pie/"} component={ListPie} />
                <Route exact path={Path + "/pago-detalle/:position/:id"} component={PagoDetalle} />
                <Route exact path={Path + "/cartola-de-pago/"} component={CartolaDePago} />
                <Route exact path={Path + "/mis-datos/"} component={MisDatos} />
                <Route exact path={Path + "/cambiar-contrasena/"} component={CambiarContrasena} />
                <Route exact path={Path + "/preguntas-frecuentes/"} component={PreguntasFrecuentes} />
                <Route exact path={Path + "/contacto/"} component={Contacto} />
                <Route exact path={Path + "/end/:status/:buy_order/:estado"} component={End} />
                <Route exact path={Path + "/postergacion"} component={Postergacion} />
                <Route component={NotFound} />
            </Switch>
    );
}

export default renderContent;