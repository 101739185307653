import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import Layout from '../../Layout'
import ListFinanciamiento from './Components/ListFinanciamiento';
import ListMantencion from './Components/ListMantencion';
import Session from './../../../service/Session';

import 'react-table/react-table.css';
import ListPostergar from './Components/ListPostergar';
import './rload.scss';

class CartolaPago extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let contratos = Session.getContratos().contrato;

        return (
            <>
                <Layout name="Cartola de Pago">
                    {contratos.length > 0 ? (
                        <Row>
                            <Col>
                                <ListPostergar contratos={contratos} />
                                <ListFinanciamiento />
                                <ListMantencion />
                            </Col>
                        </Row>
                    ) : (<><h1>No tiene contratos vigentes</h1></>)
                    }
                </Layout>
            </>
        );
    }
}
export default CartolaPago;