import React from 'react';
import BoxAgreement from '../../components/Agreement/Document/Document';
import TitleSection from '../../components/TitleSection/TitleSection.jsx';
import Layout from '../Cliente/Layout/Layout';

const Contratos = () => {
    return (
        <Layout bg={'bg-fluid-bottom'}>
            <div className="container-cartola-pago">
                <TitleSection
                    title={'Productos contratados'}
                    subtitle={'Revise aquí su X contrato'}
                    st_medida_class="d-block d-md-none"
                    all_medida_class={""}
                />
                <BoxAgreement />
            </div>
        </Layout>
    )
}

export default Contratos;