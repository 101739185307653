import React from 'react';
import PostsItems from "../../../../components/Posts/Items";

const Posts = () => {
    return (
        <div className="col-md-4 list-posts">
            <div className="content-items">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Conoce nuestras novedades</h3>
                        </div>
                    </div>
                </div>
                <div className="content-posts">
                    <div className="content-items-posts">
                        <PostsItems
                            type="3"
                            title=""
                            cant="2"
                            col={'12'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Posts;