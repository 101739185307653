import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL_VOYAGER;

export async function GET(endpoint, full_url = false) {
  if (full_url) {
    const response = await axios
      .get(endpoint)
      .then((resp) => resp)
      .catch((error) => error.response);
    return response;
  }

  const response = await axios
    .get(`${API_URL}${endpoint}`)
    .then((resp) => resp)
    .catch((error) => error.response);
  return response;
}

export async function POST(endpoint, params = {}, full_url = false) {
  if (full_url) {
    const response = await axios
      .post(endpoint, params)
      .then((resp) => resp)
      .catch((error) => error.response);
    return response;
  }

  const response = await axios
    .post(`${API_URL}${endpoint}`, params)
    .then((resp) => resp)
    .catch((error) => error.response);
  return response;
}
