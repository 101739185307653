import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import iconlogout from "./../../assets/image/icon/logout.svg";
import Avatar from "../NameUser/Avatar";
import MenuJson from "./Menu";
import "./MenuL.scss";
import { logout, getUser } from "../../services/Storage.Utils";
import { BaseUrlNewSendero } from '../../../config';

const LogoutSession = () => {
  logout();
  window.location.href = `/${BaseUrlNewSendero}`;
};

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(100%)")};
  height: auto;
  text-align: left;
  left: 0;
  border: solid 1px #f1f1f1;
  transition: transform 0.3s ease-in-out;
  z-index: 99;
  background-color: #fff;
  border-radius: 15px;
  position: fixed;
  bottom: 0;
  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }
    img {
      width: auto;
      height: 22px;
    }

    &:hover {
      color: #343078;
    }
  }
`;
const StyledBurger = styled.button`
  position: absolute;
  top: 3%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 999;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #627171;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  &.btn-cerrado {
    div {
      width: 2rem;
      height: 0.25rem;
      background: ${({ open }) => (open ? "#95C11F" : "#627171")};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) =>
          open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  }
`;

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu
      open={open}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
    >
      <main id="page-wrap">
        <div className="border_menu">
          <div className="row">
            <div className="col-3 col-md-2 text-center">
              <Avatar />
            </div>
            <div className="col-9 order-md-last col-md-10 text-left">
              <p>Bienvenido(a)</p>
              <p>{getUser().email}</p>
            </div>
            <div className="content-button">
              <Burger open={open} setOpen={setOpen} classes="btn-cerrado" />
            </div>
          </div>
        </div>
        <div className="menu-left">
          <ul className="menu-items">
            {MenuJson.map((item, index) => (
              <li key={index}>
                <Link to={item.link}>
                  <span role="img" aria-label={item.name}>
                    <img src={item.icon} alt="" />
                  </span>
                  {item.name}
                </Link>
              </li>
            ))}
            <li>
              <a onClick={LogoutSession}>
                <span role="img">
                  <img src={iconlogout} alt="" />
                </span>
                Cerrar sesión
              </a>
            </li>
          </ul>
        </div>
      </main>
    </StyledMenu>
  );
};

const Burger = ({ open, setOpen, classes }) => {
  return (
    <StyledBurger
      open={open}
      onClick={() => setOpen(!open)}
      className={classes}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const App = () => {
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  return (
    <>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </>
  );
};
export default App;
