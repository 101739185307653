import React from "react";
import "./BoxText.scss";

const BoxText = ({ title1, title2, subtitle }) => {
  return (
    <div className="box-top-text">
      <h1 className="box-top-text__title">
        {title1} <span className="tx_green">{title2}</span>
      </h1>
      {subtitle !== "" && <p className="box-top-text__subtitle">{subtitle}</p>}
    </div>
  );
};

export default BoxText;
