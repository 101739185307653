import resumen from "./../../assets/image/icon/resumen.svg";
import contrato from "./../../assets/image/icon/contrato.svg";
import datos from "./../../assets/image/icon/datos.svg";
import preguntas from "./../../assets/image/icon/preguntas.svg";
import cartola from "./../../assets/image/icon/cartola.svg";
import acepta_pie from "./../../assets/image/icon/acepta_pie.svg";

import { BaseUrlNewSendero } from '../../../config';

export default [
    {
        icon: resumen,
        name: 'Resumen',
        name_desktop: 'Resumen',
        link: `/${BaseUrlNewSendero}/resumen`
    },
    {
        icon: contrato,
        name: 'Ver Contratos',
        name_desktop: 'Contrato',
        link: `/${BaseUrlNewSendero}/contratos`
    },
    {
        icon: datos,
        name: 'Datos personales',
        name_desktop: 'Datos personales',
        link: `/${BaseUrlNewSendero}/datos-personales`
    },
    {
        
        icon: acepta_pie,
        name: 'Aceptar y pagar pie Contrato',
        name_desktop: 'Productos contratados',
        link: `/${BaseUrlNewSendero}/acepta-y-pagar-pie`
    },
    { 
        icon: cartola,
        name: 'Cartola de pago',
        name_desktop: 'Cartola de pago',
        link: `/${BaseUrlNewSendero}/cartola-de-pago`
    },
    {
        icon: preguntas,
        name: 'Preguntas frecuentes',
        name_desktop: 'Preguntas frecuentes',
        link: `/${BaseUrlNewSendero}/preguntas-frecuentes`        
    }
]





