import React, { Component } from 'react'

import { Link } from "react-router-dom"

import Logo from './Assets/LogoSendero.png';
import Icon247 from '../../../assets/images/icons/24-7.png';

import Session from '../../../service/Session';
import SC from './Assets/icon7.png';
import CP from './Assets/icon6.png';
import MD from './Assets/icon5.png';
import CC from './Assets/icon4.png';
import PF from './Assets/icon2.png';
import C from './Assets/icon3.png';
import CS from './Assets/icon1.png';
import PC from './Assets/icon-20.svg';

export default class SiedebarLeft extends Component {
	constructor(props) {
		super(props);
		var openS = 'open';
		if ('sidebarOpen' in localStorage) {
			openS = localStorage.getItem('sidebarOpen');
		}
		this.state = {
			open: openS,
			close: false,
			collapse: window.location.pathname.split('/')[1],
			draft: 0,
			prorroga: Session.getInfoUser().prorroga
		};
		this.toggleList = this.toggleList.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.activarMenu = this.activarMenu.bind(this);
	}
	handleClick() {
		var openS = this.state.open === 'open' ? 'close' : 'open'
		localStorage.setItem('sidebarOpen', openS);
		this.setState(
			state => ({
				open: openS
			})
		);
	}
	toggleList(e, link) {
		let l = this.state.collapse;
		if (l === link) {
			link = false;
		}
		e.preventDefault();
		this.setState(state => ({ collapse: link }));
	}
	handleClose() {
		
		let url_return = '';
		
		if(localStorage.getItem("iscontrato") == 1){
			url_return = '/acepta-contrato/';
		} else {
			url_return = '/mi-sendero/';
		}

		Session.close();
		window.location.assign(url_return);
	
	}
	activarMenu() {
		this.props.onClick(0);
	}
	render() {
		const { prorroga } = this.state;
		return (
			<div className='sidebar-left sidenav' >
				<ul className="p-0">
					<div className="">
						<span className="bar-sidebar-left">
							<img src={Logo} style={{ width: 100 + '%' }} className="img" alt="logo" />
						</span>
						<button className="botonMenu" onClick={this.activarMenu}>X</button>
					</div>
					<br />
					<div className="content-bienvenida">
						<p>Bienvenido a Mi Sendero.</p>
						<p>{Session.getInfoUser().usuario}</p>
					</div>
					<br />
					<a href="/mi-sendero/servicios-contratados/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={SC} className="iconMenu" alt="" />Mis Servicios Contratados
							</span>
						</li>
					</a>
					<a href="/mi-sendero/cartola-de-pago/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={CP} className="iconMenu" alt="" />Cartola de Pago
							</span>
						</li>
					</a>
					<a href="/mi-sendero/pago-pie/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={SC} className="iconMenu" alt="" />Aceptar y Pagar Pie Contrato
							</span>
						</li>
					</a>
					<a href="/mi-sendero/mis-datos/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={MD} className="iconMenu" alt="" />Mis Datos
							</span>
						</li>
					</a>
					<a href="/mi-sendero/cambiar-contrasena/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={CC} className="iconMenu" alt="" />Cambiar Contraseña
							</span>
						</li>
					</a>

					<Link to="/mi-sendero/preguntas-frecuentes/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={PF} className="iconMenu" alt="" />Preguntas Frecuentes
							</span>
						</li>
					</Link>
					<Link to="/mi-sendero/contacto/">
						<li className="li-link">
							<span className="sidebar-label">
								<img src={C} className="iconMenu" alt="" />Contacto
							</span>
						</li>
					</Link>
					{parseInt(prorroga) === 1 && (
						<Link to="/mi-sendero/postergacion/">
							<li className="li-link">
								<span className="sidebar-label">
									<img src={PC} className="iconMenu" alt="" />Postergación de Cuotas
							</span>
							</li>
						</Link>
					)}
					<li className="li-link" onClick={this.handleClose}>
						<span className="sidebar-label">
							<img src={CS} className="iconMenu" alt="" />Cerrar sesión
						</span>
					</li>
					<div>
						<div className="contact content-contact-mi-sendero text-center">
							<div className="rrss">
								<a href="https://www.facebook.com/Senderoparque/"><i className="iconrrss fab fa-facebook-f"></i> </a>
								<a href="https://www.instagram.com/senderoparque/"><i className="iconrrss fab fa-instagram"></i> </a>
								<a href="https://www.youtube.com/channel/UCv8fufJ35tJDP2E92NdHgww"><i className="iconrrss fab fa-youtube"></i> </a>
							</div>
							<div>
								<p className="contacto-contactenos"><strong>CONTÁCTENOS LAS 24 HORAS</strong></p>
								<div className="contacto-phone">
									<img src={Icon247} alt='' className="img img-247" />
									<div>
										<a href="tel:6007363376"> 600 736 3376</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ul>
			</div>
		);
	}
}