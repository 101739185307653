function clean(rut) {
  return typeof rut === "string"
    ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
    : "";
}
function unformatRut(rut) {
  return rut ? rut.replace(/\./g, "") : "";
}
function validate(rutValue) {
  const rut = unformatRut(rutValue);
  const rexp = new RegExp(/^([0-9])+-([kK0-9])+$/);
  if (rut.match(rexp)) {
    const RUT = rut.split("-");
    const elRut = RUT[0].split("");
    let factor = 2;
    let suma = 0;
    let dv;
    for (let i = elRut.length - 1; i >= 0; i--) {
      factor = factor > 7 ? 2 : factor;
      suma += parseInt(elRut[i]) * parseInt(factor++);
    }
    dv = 11 - (suma % 11);
    if (dv === 11) {
      dv = 0;
    } else if (dv === 10) {
      dv = "k";
    }

    if (dv.toString() === RUT[1].toLowerCase()) {
      return true;
    }
    return false;
  }
  return false;
}

function format(rut) {
  rut = clean(rut);

  let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
  for (let i = 4; i < rut.length; i += 3) {
    result = rut.slice(-3 - i, -i) + "." + result;
  }

  return result;
}

module.exports = { validate, clean, format };
