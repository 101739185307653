import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import logoSendero from './../../../assets/images/logo-sendero.svg';
import { Alert, Col, Button, FormGroup } from 'reactstrap';
import Api from '../../../service/Api';
import Validate from '../../../service/Validate';
class Recuperar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rut: '',
      txtMsj: '',
      AlertClass: '',
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.setForm = this.setForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.activarPop = this.activarPop.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  async setForm() {
    let response = await Api.recuperarContrasenia(this.state);
    if (response.status === 'success') {
      this.setState({
        txtMsj: response.result.msg,
        AlertClass: 'alert-success',
        rut: '',
      });
    } else {
      this.setState({
        txtMsj: response.result.msg,
        AlertClass: 'alert-danger',
      });
    }
  }
  handleInputChange(event) {
    this.setState({
      txtMsj: '',
    });
    const target = event.target;
    const name = target.name;
    if (name === 'rut') {
      let disabled = false;
      // let valor = event.target.value;
      // valor = valor.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
      const valor = Validate.formatterRut(event.target.value);
      console.log(valor);
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(valor.replace(/\./g, ''))) {
        disabled = true;
      }
      this.setState({
        rut: valor,
        disabledButton: disabled,
      });
    }
    if (this.state.rut) {
      this.setState({
        disabledButton: true,
      });
    } else {
      this.setState({
        disabledButton: false,
      });
    }
  }

  activarPop(e) {
    this.setState({ modal: !this.state.modal });
  }
  render() {
    return (
      <>
        <p
          onClick={(e) => {
            this.activarPop(e);
          }}
          className="link-pop-up"
        >
          ¿Olvidó su contraseña?
        </p>
        <Modal isOpen={this.state.modal}>
          <div id="content_login_rut" className="container-popup">
            <div className="btn-close-modal" onClick={this.toggle}>
              <i className="fas fa-times"></i>
            </div>
            <header>
              <div className="content-logo">
                <img src={logoSendero} alt="" />
              </div>
            </header>
            <ModalBody>
              <FormGroup row>
                <Col sm={12}>
                  <input
                    type="text"
                    name="rut"
                    id="rut"
                    onChange={(e) => {
                      this.handleInputChange(e);
                    }}
                    value={this.state.rut || ''}
                    placeholder="Rut"
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12} className="text-center">
                  {this.state.txtMsj !== '' ? (
                    <Alert className={'alert ' + this.state.AlertClass}>
                      {this.state.txtMsj}
                    </Alert>
                  ) : (
                    ''
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Button
                    id="consulta_rut"
                    type="submit"
                    onClick={this.setForm}
                    disabled={!this.state.disabledButton}
                    className="btn btn-verde button btn-popup"
                  >
                    Recuperar contraseña
                  </Button>
                </Col>
              </FormGroup>
            </ModalBody>
          </div>
        </Modal>
      </>
    );
  }
}

export default Recuperar;
