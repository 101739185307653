import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import Layout from '../Cliente/Layout/Layout';
import Vineta from './../../../pago-rapido/Assets/Images/vineta_up.png';

const dat_collapse = [{
    name: '1. ¿Qué son las cuotas morosas?',
    description: 'Se denomina cuota morosa a la lentitud, dilación o demora en el cumplimiento de un compromiso dentro de la franja de tiempo establecido previamente.'
 }
//, {
//     name: '2. ¿Qué son las cuotas vigentes?',
//     description: 'Se denomina cuota morosa a la lentitud, dilación o demora en el cumplimineto de un compromiso dentro de la franja de tiempo establecido previamente.'
// }, {
//     name: '3. Una vez que he hecho el pago, ¿cuándo dejo de aparecer como deudor en el sistema?',
//     description: 'Se denomina cuota morosa a la lentitud, dilación o demora en el cumplimineto de un compromiso dentro de la franja de tiempo establecido previamente.'
// }, {
//     name: '3. Una vez que he hecho el pago, ¿cuándo dejo de aparecer como deudor en el sistema?',
//     description: 'Se denomina cuota morosa a la lentitud, dilación o demora en el cumplimineto de un compromiso dentro de la franja de tiempo establecido previamente.'
// }, {
//     name: '3. Una vez que he hecho el pago, ¿cuándo dejo de aparecer como deudor en el sistema?',
//     description: 'Se denomina cuota morosa a la lentitud, dilación o demora en el cumplimineto de un compromiso dentro de la franja de tiempo establecido previamente.'
// }
];

const PreguntasFrecuentes = (props) => {
    const [collapse, setCollapse] = useState(0);
    const toggle = (e, tab) => {
        e.preventDefault();
        if (collapse !== tab) {
            setCollapse(tab)
        } else {
            setCollapse(0);
        }
    }

    return (
        <>
            <Layout bg={'bg-fluid-bottom'}>
                {typeof dat_collapse !== 'undefined' && dat_collapse.map((item, index) => (
                    <div className="collapse-preguntas-frecuentes" key={index}>
                        <div onClick={(e) => { toggle(e, index + 1) }} className={(parseInt(collapse) === (index + 1) ? 'open-collapse' : '') + ' text-list'}>
                            {item.name}
                            <img src={Vineta} className="float-right icon-list" alt="" />
                        </div>
                        <Collapse isOpen={parseInt(collapse) === (index + 1) ? true : false}>
                            <div className="collapse-contenido">
                                <p>
                                    {item.description}
                                </p>
                            </div>
                        </Collapse>
                    </div>
                ))}
            </Layout>
        </>
    )
}


export default PreguntasFrecuentes;