import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Session from './../service/Session'
import Auth from './auth'
import Test from './index'
import End from './End'
import PagoRapidoKhipuSuccess from './Components/PagoRapidoKhipuSuccess';
import PagoRapidoKhipuError from './Components/PagoRapidoKhipuError';
import Layout from '../pago-rapido/Layout';

const renderContent = (props) => {
	const Path = props.match.path;
	return (
		<BrowserRouter>
			{Session.isAuth() ?
				<Switch>
					<Route exact path={Path + "/end"} component={End} />
					<Route exact path={Path + "/"} component={Test} />
					<Route path={Path + "/auth/"} component={Auth} />
					<Route path={Path + "/payment/khipu/success"} component={PagoRapidoKhipuSuccess} />
					<Route path={Path + "/payment/khipu/Error"} component={PagoRapidoKhipuError} />
				</Switch>
				:
				<>
					<Route path={Path + "/auth/"} component={() => <><Layout><Auth /></Layout></>} />
					<Redirect to={Path + "/auth/"} />
				</>
			}
		</BrowserRouter>
	);
}

export default renderContent;