import React, { Component } from "react";
import { Link } from "react-router-dom";
import menu from "./dataMenuMM";
import "./MegaMenu.scss";
import Url from "./Url";
import pr from "./../../../assets/images/icons/pagorapido.svg";
import em from "./../../../assets/images/icons/emergencia.svg";
import ms from "./../../../assets/images/icons/misenderoperfil.svg";
import { BaseUrlNewSendero } from '../../../config';
class MegaMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMM: false,
    };
    this.ShowMegaMenu = this.ShowMegaMenu.bind(this);
  }
  ShowMegaMenu() {
    this.setState((prevState) => ({
      showMM: !prevState.showMM,
    }));
  }

  render() {
    const { showMM } = this.state;
    const { pagorapido, misendero, registrar, menuSite } = this.props;
    var menu__top = [
      {
        name: "PAGO RAPIDO",
        url: "/pago-rapido",
        status: 1,
        color: "blue",
        icon: pr,
      },
      {
        name: "MI SENDERO",
        url: `/${BaseUrlNewSendero}`,
        status: 1,
        color: "green",
        icon: ms,
      },
      {
        name: "EMERGENCIA",
        url: "tel:6007363376",
        status: 1,
        color: "red",
        icon: em,
      },
    ];
    let is_contrato  = localStorage.getItem('iscontrato');
    if(is_contrato == 1)  {
      return(<> {menuSite}</>)
    } else {
      return (
        (<div className="mega-menu">
        <div className="mega-menu__top">
          <div>
            <div className="row">
              <div className="col-md-1 d-none d-md-block">
                <div className="content-mm">
                  <p onClick={this.ShowMegaMenu} className="open_click">
                    <div className="burger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <span>Menú</span>
                  </p>
                  <div className={showMM ? "showMenu" : "hideMenu"}>
                    <div className="close_click" onClick={this.ShowMegaMenu}>
                        <span>X</span> Menú
                      </div>
                      <div className="opacity" onClick={this.ShowMegaMenu}>
                    <div className="showMenu__space_white">
                      
                      {menu.menu.map((item) => (
                        <Url
                          name={item.name}
                          classmenu={item.classmenu}
                          url={item.ruta}
                          local={
                            item.ruta.includes("http") ||
                            item.ruta.includes("tel:")
                          }
                          target={item.target}
                          parent={
                            typeof item.children !== "undefined" ? true : false
                          }
                          ShowMegaMenu={this.ShowMegaMenu}
                          component={
                            typeof item.children !== "undefined" && (
                              <div className="item__space_white">
                                {item.children.map((item2) => (
                                  <Url
                                    name={item2.name}
                                    url={
                                      typeof item2.parent !== "undefined" &&
                                      !item2.parent
                                        ? item2.ruta
                                        : item.ruta + item2.ruta
                                    }
                                    local={
                                      item2.ruta.includes("http") ||
                                      item2.ruta.includes("tel:")
                                    }
                                    target={item.target}
                                    ShowMegaMenu={this.ShowMegaMenu}
                                  />
                                ))}
                              </div>
                            )
                          }
                        />
                      ))}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <Link to={"/"}>
                  <div
                    className={
                      "option " +
                      (window.location.pathname.split("/")[1] === "" &&
                        "active")
                    }
                  >
                    Inicio
                  </div>
                </Link>
                <Link to={"/parques"}>
                  <div
                    className={
                      "option " +
                      (window.location.pathname.split("/")[1] === "parques" &&
                        "active")
                    }
                  >
                    Parques
                  </div>
                </Link>
                <Link to={"/parque-crematorio"}>
                  <div
                    className={
                      "option " +
                      (window.location.pathname.split("/")[1] ===
                        "parque-crematorio" && "active")
                    }
                  >
                    Cremación
                  </div>
                </Link>
                <a href="https://www.funerariasendero.cl/" target="_self">
                  <div className="option">Funeraria</div>
                </a>
              </div>
              <div className="col-12 offset-md-1 col-md-5 d-none d-md-block">
                {menu__top.map(
                  (item, index) =>
                    item.status === 1 &&
                    (item.url.includes("tel:") ? (
                      <a href={item.url} key={index}>
                        <div className={"btn-top " + item.color}>
                          <img src={item.icon} /> {item.name}
                        </div>
                      </a>
                    ) : (
                      <Link to={item.url} key={index}>
                        <div className={"btn-top " + item.color}>
                          <img src={item.icon} /> {item.name}
                        </div>
                      </Link>
                    ))
                )}
              </div>
            </div>
          </div>
        </div>
        {menuSite}
      </div>)
      );
    }
    
  }
}
export default MegaMenu;