import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import TitleSection from '../../components/TitleSection/TitleSection';
import { getContrato } from '../../services/Storage.Utils';

import Layout from '../Cliente/Layout/Layout';

const HistorialPago = (props) => {
    const { id } = useParams();
    const Agreement = getContrato()[id];
    return (
        <Layout>
            <TitleSection
                title={"Historial de pago"}
                subtitle={''}
                all_medida_class={""}
                st_medida_class={""}
            />
            {
                [Agreement].map((item) => (
                    Object.keys(item).map((e) =>
                    (
                        item[e]?.historial && (
                            item[e].historial.map((cuota) => (
                                <Row>
                                    <Col>
                                        <div className="box-historial">
                                            <h2>Cuota {cuota.numero_cuota}</h2>
                                            <p>Fecha vencimiento: {cuota.fecha_vencimiento}</p>
                                            <p>Fecha pago: {cuota.fecha_pago}</p>
                                            <p>Monto: ${cuota.monto}</p>
                                            <p>Servicio: {e}</p>
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        )
                    ))
                ))
            }
        </Layout>
    )
}

export default HistorialPago