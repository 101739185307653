import React, { Component } from 'react';
import desktop from "./../assets/images/icons/social-media.svg";
import mobile from "./../assets/images/icons/social-media.d6e2a21f.svg";
class Chat extends Component {
    render() {
        const { type } = this.props;
        return (
            <>
                <div className={"content-" + type} id="chat_wsp_mi_sendero">
                    <a href="https://wa.me/56937528099?text=Hola" target="_blank" rel="noopener noreferrer" alt="whatsapp Sendero">
                        <img src={type === 'desktop' ? desktop : mobile} alt="whatsapp Sendero" />
                    </a>
                </div>
            </>
        );
    }
}

export default Chat;