import React, { Component } from 'react';
import { Container } from 'reactstrap';
import SideBarLeft from './Components/SideBarLeft';
import NavBarTop from './Components/NavBarTop';
import PopUpMantencion from './PopUp/PopUp';
import Chat from './../../components/Chat';
export default class MainPrivate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			statusMenu: false
		};
		this.onClick = this.onClick.bind(this)
	}
	onClick(val) {
		this.setState({
			statusMenu: val === 0 ? false : true
		})
	}
	async componentDidMount() {
		//console.log('componentes montados');	
		document.getElementById('chat_wsp_mi_sendero').style.display = 'block';
	}
	render() {
		const { statusMenu } = this.state;
		return (
			<>
				<Container fluid>
					<div className={"mainSideBar " + (statusMenu ? 'activeMenu' : 'desactiveMenu')}>
						<SideBarLeft onClick={this.onClick} />
					</div>
					<div className="mainContent mainMiSendero">
						<Container fluid className="m-b">
							<div>
								<button className="botonMenu" onClick={(e) => { this.onClick(1) }}>
									<span></span>
									<span></span>
									<span></span>
								</button>
								<NavBarTop nameNavBarTop={this.props.name} />
							</div>
							<div>
								{this.props.children}
							</div>
						</Container>
					</div>
					<Chat type='desktop' />
				</Container>
				<PopUpMantencion />
			</>
		);
	}
}