import React from 'react';
import Title from '../../../components/Title/BoxText';
import Layout from '../Layout/Layout';
import FormLogin from './Form';
import Posts from './Posts';
import './Login.scss';

const Login = (props) => {
    return (
        <>
        {
            props.iscontrato === 1 ? 
            (    
            <div className='col-md-12 bg-mobile d-flex justify-content-center align-items-center vh-100 align-content-center'>
                <div className="ct-public__form" style={{width: '300px'}}>
                    <Title title1={'Ingresa a'} title2={'firma de contrato'} subtitle={'Completa tus datos para continuar'} />
                    <FormLogin iscontrato={props.iscontrato}/>
                </div>
            </div>
            ) :
            (
                <Layout classes={'ct-public'} bg={'bg-fluid-bottom'}>
                    <div className='col-md-8 bg-mobile'>
                        <div className="ct-public__form">
                            <Title title1={'Ingresa a'} title2={'Mi Sendero'} subtitle={'Completa tus datos para continuar'} />
                            <FormLogin iscontrato={0}/>
                        </div>
                    </div>
                    <Posts />
                </Layout>
            )
        }
        </>
    )
}

export default Login;