import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Alert from '../../../components/Alert/Alert';
import { setUserLogin } from '../../../services/Storage.Utils';
import * as Api from "../../../api";
import { BaseUrlNewSendero, Recaptcha_key } from '../../../../config';
import ReCAPTCHA from "react-google-recaptcha";

const FormLogin = (props) => {

    const recaptchaRef = React.useRef();

    const [msg, setMsg] = useState('');
    const [status, setStatus] = useState('');
    const [loader, setLoader] = useState(false);
    const [inputs, setInputs] = useState({
        rut: "",
        password: "",
    });
    const handleLogin = async () => {

        setLoader(true);

        const { rut, password } = inputs;
        if (rut === '') {
            setMsg('Debe ingresar un Rut');
            setStatus('danger');
            setLoader(false);
            return false;
        }
        if (password === '') {
            setMsg('Debe ingresar Password');
            setStatus('danger');
            setLoader(false);
            return false;
        }

        const token = await recaptchaRef.current.executeAsync();

        const recaptcha = await Api.recaptcha({ token: token });

        if (recaptcha.validation) {

            const response = await Api.SingIn(inputs)
            const { status, validations, result } = response;
            if (status === 'success') {
                setUserLogin(result.api_token, result.client);
                localStorage.setItem('iscontrato',props.iscontrato);
                window.location.href = `/${BaseUrlNewSendero}/resumen`;
            } else {
                setMsg(validations.result.msg);
                setStatus(validations.status)
            }

        } else {
            setMsg(recaptcha.msg);
            setStatus(recaptcha.status)
        }

        setLoader(false);

    };
    const handleInput = (e) => {
        let valor = e.target.value;
        if (e.target.name === "rut") {
          valor = valor
            .replace(/[.-]/g, "")
            .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
         
        }
    
        setInputs({
          ...inputs,
          [e.target.name]: valor,
        });
        // setInputs({
        //     ...inputs,
        //     [e.target.name]: e.target.value,
        // });
    };

    return (

        <form>
            <div className="content-form">
                <div className="form-group">
                    <label>RUT</label>
                    <input type="text" id="rut" name="rut" className="form-control" autocomplete="off" onChange={handleInput} placeholder="12.345.678-8" value={inputs.rut} />
                </div>
                <div className="form-group">
                    <label>CLAVE</label>
                    <input type="password" id="password" name="password" className="form-control" autocomplete="off" onChange={handleInput} placeholder="*******" />
                </div>
                <Alert msg={msg} status={status} />
            </div>
            <div className="form-group">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={Recaptcha_key}
                    style={{ display: "inline-block" }}
                    theme="dark"
                    size="invisible"
                />
                <Button
                    classes={'bt-ms _bt-green'}
                    handleLogin={handleLogin}
                    loader={loader}
                    name={'Ingresar'}
                />
            </div>
            <div className="form-group row">
                <div className="col-7 text-left">
                    {
                        props.iscontrato == 1 ? (<><Link to={`/acepta-contrato/recover`} className='recover tx _black'>¿Olvidó su contraseña?</Link></>) : (<><Link to={`/${BaseUrlNewSendero}/recover`} className='recover tx _black'>¿Olvidó su contraseña?</Link></>)
                    }
                    
                </div>
                <div className="col text-right">
                    {
                        props.iscontrato == 1 ? (<><Link to={`/acepta-contrato/register`} className="recover tx _black">Registrarse</Link></>) : (<><Link to={`/${BaseUrlNewSendero}/register`} className="recover tx _black">Registrarse</Link></>)
                    }
                </div>
            </div>
        </form>

    )
}
export default FormLogin;