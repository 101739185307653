import React from "react";

const Alert = ({ msg, status }) => {
  return (
    msg !== "" && (
      <div className="form-group">
        <div className={`alert alert-${status}`}>{msg}</div>
      </div>
    )
  );
};

export default Alert;
