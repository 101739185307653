import React from "react";
import { getContrato } from "../../../services/Storage.Utils";
import TitleSection from "../../TitleSection/TitleSection";
import ItemAgreement from "./Item";
import './../Agreement.scss';

const BoxAgreement = ({ contrato }) => {
  const Agreement = getContrato();
  return (
    <>
      {typeof contrato !== "undefined" ? (
        [Agreement[contrato]].map((item, index) => (
          <ItemAgreement {...item} key={index} hidden_button={true} />
        ))
      ) : (
        <>
          {/*<TitleSection
            title={"Resumen"}
            subtitle={""}
            all_medida_class={""}
            st_medida_class={""}
          />*/}
          <div className="row">
            {Agreement &&
              Agreement !== null &&
              Object.keys(Agreement).map((i) =>
                [Agreement[i]].map((item, index) => (
                  <ItemAgreement {...item} key={index} hidden_button={false} />
                ))
              )}
          </div>
        </>
      )}
    </>
  );
};

export default BoxAgreement;
