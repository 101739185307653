import { useState } from "react";
import { format } from "./../services/Utils";

export const useFormState = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "rut") {
      target.value = format(target.value);
    }
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };
  return [values, handleInputChange, reset];
};
