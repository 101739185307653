import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Header from "../../../components/Header/Header";
import FooterDesktop from "./../../../../components/Footer/Desktop";
import Footer from "../../../components/Footer/Footer";
import FooterFixed from "../../../components/Footer/FooterFixed";
import { POST , GET} from "../../../services/Api";

import "./../../../assets/scss/main.scss";
import { getUser } from "../../../services/Storage.Utils";

let contrato = {};

const Layout = ({ bg, children }) => {
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  let iscontrato = localStorage.getItem('iscontrato');

  useEffect(() => {
    function fetchMyAPI() {
      if (
        parseInt(localStorage.getItem("update")) === 1 ||
        localStorage.getItem("contrato") === null
      ) {
        setLoader(true);
        POST("api/v2/agreements", {
          rut: getUser().rut,
        }).then((response) => {
          const { result, status } = response.data;
          if (
            response &&
            response.hasOwnProperty("status") &&
            response.status === 200
          ) {
            if (status === "success") {
              localStorage.removeItem("contrato");
              localStorage.setItem("contrato", JSON.stringify({}));
              localStorage.setItem("update", 0);
              result[0].map((item) => <>{ejecutarContrato(item)}</>);
              localStorage.setItem("contrato", JSON.stringify(contrato));
            } else {
              setMsg(result.msg);
            }
          } else {
            setMsg(result.msg);
          }
          setLoader(false);
        });
      }
    }

    function getObtenerAceptantes() {
    
      let rut = getUser().rut;

      if (
        parseInt(localStorage.getItem("update")) === 1 ||
        localStorage.getItem("contrato") === null
      ) {

        GET('api/v2/obtener_aceptantes/'+rut).then((response) => {

          const { result, status } = response.data;
              if (status === "success") {
                localStorage.setItem("infcontratos", JSON.stringify(result));
              } 

        });
      }

    }

    getObtenerAceptantes();
    fetchMyAPI();
  }, []);
  function ejecutarContrato(item) {
    // console.log(item);
    if(contrato[item.contrato] != undefined) {
      
     
      if (typeof item.pie === "object") {
        contrato[item.contrato].alerta_pie = true;
        Object.keys(item).map((key) => {
          contrato[item.contrato]["pie"] = item.pie;
        });
      }
      if (typeof item.mpu === "object") {
        contrato[item.contrato].alerta_mpu = true;
        // Object.keys(item).map((key) => {
        //   contrato[item.contrato]["pie"] = item;
        // });
      }
      
    } else {

      contrato[item.contrato] = item;
      // console.log(contrato);
      if (typeof item.pie === "object") {
        contrato[item.contrato].alerta_pie = true;
      }
      if (typeof item.mpu === "object") {
        contrato[item.contrato].alerta_mpu = true;
      }
    }
  }
  return (
    <>
      {/*<Header />*/}
      <div id="outer-container">
        <Header />
        <main className={bg} style={{'margin-top' : iscontrato == 1 ? '-120px' : 'none'}}>
          <div className="container">
            {loader ? (
              <div className="text-center spinner">
                <Spinner style={{ width: "5rem", height: "5rem" }} />
              </div>
            ) : msg !== "" ? (
              <p>{msg}</p>
            ) : (
              <>{children}</>
            )}
          </div>
        </main>
        <div className="d-none d-md-block">
          <FooterDesktop />
        </div>
        <div className="d-block d-md-none">
          <footer>
            <Footer />
            <FooterFixed />
          </footer>
        </div>
      </div>
    </>
  );
};

export default Layout;
