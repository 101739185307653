import React, { useState } from 'react';
import Layout from '../Cliente/Layout/Layout';
import { useLocation } from 'react-router-dom';
import { useFormState } from '../../hooks/useForm';
import TablePie from '../../components/TabServicios/TablePie';
import { getContrato, getUser } from '../../services/Storage.Utils';
import ButtonPay from '../../components/Pay/ButtonPay';
import TitleSection from '../../components/TitleSection/TitleSection';

var selectedCuota = {};
const PagoPie = () => {
    const Agreement = getContrato();
    const { state } = useLocation()
    const id = typeof state?.id !== 'undefined' ? state.id : '';
    const [amount, setMontoTotal] = useState(0);
    const [disabledPagar, setDisabledPagar] = useState(false);
    const calculaMontoTotal = () => {
        var valor = 0;
        Object.keys(selectedCuota).map((item) => (
            valor += parseInt(selectedCuota[item].MONTO_CUOTA)
        ))
        setDisabledPagar(valor > 0 ? false : true);
        setMontoTotal(valor);
    }
    const onClick = (e, items) => {
        var objeto = {};
        if (e) {
            [items].forEach(todo => {
                objeto = {
                    'NUMERO_CONTRATO': todo.NUMERO_CONTRATO,
                    'NUMERO_CUOTA': todo.NUMERO_CUOTA,
                    'CONCEPTO_RECAUDACION': todo.CONCEPTO_RECAUDACION,
                    'MONTO_CUOTA': parseInt(todo.MONTO_CUOTA),
                    'NUMERO_DOCUMENTO': todo.NUMERO_DOCUMENTO,
                    'CUOTA_MOROSA': todo.CUOTA_MOROSA,
                    'CODIGO_COMERCIO': todo.CODIGO_COMERCIO,
                };
                selectedCuota[todo.NUMERO_CONTRATO + '-' + todo.NUMERO_CUOTA + '-' + todo.CONCEPTO_RECAUDACION] = objeto;
            });
        } else {
            [items].forEach((todo) => {
                delete selectedCuota[todo.NUMERO_CONTRATO + '-' + todo.NUMERO_CUOTA + '-' + todo.CONCEPTO_RECAUDACION];
            })
        }
    }

    const [formValues,] = useFormState({ selectedCuotaPay: selectedCuota });
    const { selectedCuotaPay } = formValues;
    var cuotasSeleccionadas = JSON.stringify(selectedCuotaPay);
    const validate = (v) => {
        if (v === 'undefined') {
            return false;
        }
        return true;
    }
    function viewBuy() {

        let b_return = false;
        Object.keys(Agreement).map((i) => {
            [Agreement[i]].map((item) => {
                b_return = typeof item.pie === 'object' ? true : false
            })
        })
        return b_return;
    }

    return (
            <Layout bg={'bg-fluid-bottom'}>
                <div className="container-cartola-pie">
                    {
                        viewBuy() ? (
                            <>
                                {id > 0 ? (
                                    [Agreement[id]].map((item) => (
                                        typeof item.pie === 'object' && (
                                            <div className="container-cartola-pie__box">
                                                <TitleSection
                                                    title={`Contrato ${item.producto} ${item.contrato}`}
                                                    subtitle={`Bienvenido ${getUser().nombre}`}
                                                    all_medida_class={""}
                                                    st_medida_class={""}
                                                />
                                                <TablePie
                                                    {...item}
                                                    title={item.titulo}
                                                    text={'Total Pago Pie Sendero'}
                                                    id={'table-gray'}
                                                    type={'P'}
                                                    onClick={onClick}
                                                    calculaMontoTotal={calculaMontoTotal}
                                                />
                                            </div>
                                        )
                                    ))
                                ) : (
                                    Object.keys(Agreement).map((i) =>
                                        [Agreement[i]].map((item) => (
                                            typeof item.pie === 'object' && (
                                                <div className="container-cartola-pie__box">
                                                    <TitleSection
                                                        title={`Contrato ${item.producto} ${item.contrato}`}
                                                        subtitle={`Bienvenido ${getUser().nombre}`}
                                                        all_medida_class={""}
                                                        st_medida_class={""}
                                                    />
                                                    <TablePie
                                                        {...item}
                                                        title={item.titulo}
                                                        text={'Total Pago Pie Sendero'}
                                                        id={'table-gray'}
                                                        type={'P'}
                                                        onClick={onClick}
                                                        calculaMontoTotal={calculaMontoTotal}
                                                    />
                                                </div>
                                            )
                                        ))
                                    )
                                )}
                                <ButtonPay
                                    amount={amount}
                                    disabledPagar={disabledPagar}
                                    cuotasSeleccionadas={cuotasSeleccionadas}
                                    nameBtPay={'Aceptar y Pagar pie Contrato'}
                                    nameBtCartola={'Descargar PDF'}
                                />
                            </>
                        ) : (
                            <h2>No contiene pago Pie disponibles</h2>
                        )
                    }

                </div>
            </Layout>
    )
}


export default PagoPie;