import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../../services/Storage.Utils";
import MenuJson from "./Menu";
import "./MenuH.scss";
import { BaseUrlNewSendero } from '../../../config';

const LogoutSession = () => {
  let url_retorno = '';

  if(localStorage.getItem('iscontrato') == 1) {
  
    url_retorno = `/acepta-contrato`;  
  
  } else {

    url_retorno = `/${BaseUrlNewSendero}`;
  
  }
  
  logout();
  
  window.location.href = url_retorno;

};
const Horizontal = () => {
  return (
    <main id="page-wrap">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="nav menu-horizontal">
          <div className="container">
            <ul className="navbar-nav mr-auto menu-items">
              {MenuJson.map((item, index) => (
                <li key={index} className="nav-item">
                  <Link to={item.link} className="nav-link">
                    {item.name_desktop}
                  </Link>
                </li>
              ))}
              <li>
                <a onClick={LogoutSession}>Cerrar sesión</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </main>
  );
};

export default Horizontal;
