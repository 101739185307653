import Network from './Network';

class Api {
  async setForm(data) {
    return await Network.post('api/send/contacto', data);
  }

  async actualizaMisDatos(data) {
    return await Network.post('api/cliente/editar', data);
  }

  async registrarUsuario(data) {
    return await Network.post('api/clientes', data);
  }

  async recuperarContrasenia(data) {
    return await Network.post('api/cliente/recuperar', data);
  }

  async setContrasena(data) {
    return await Network.post('api/clientes/cambiar', data);
  }

  async Login(data) {
    return await Network.post('api/login', data);
  }

  async ValidarPreguntas(data) {
    return await Network.post('api/cliente/valid/pregunta', data);
  }
  async aceptarCondiciones(data) {
    return await Network.post('api/pagoPie/aceptar', data);
  }
  async verficaAceptar(data) {
    return await Network.post('api/pagoPie/verifica', data);
  }
  async confirmarFirma(data) {
    return await Network.post('api/confirmar-firma', data);
  }

  async getPreguntasFrecuentes(data) {
    let response = await Network.get('api/web/preguntas-frecuentes', data);
    if (response) {
      return response;
    }
  }
  async cuotasPagoRapido(data) {
    let response = await Network.get('api/web/cuotas-pago-rapido', data);
    if (response) {
      return response;
    }
  }
  async loginPagoRapido(data) {
    let response = await Network.get('api/web/login-pago-rapido', data);
    if (response) {
      return response;
    }
  }
  async generaProrroga(data) {
    let response = await Network.get('api/cliente/genera_prorroga', data);
    if (response) {
      return response;
    }
  }
  async verificarPostergar(data) {
    let response = await Network.get('api/cliente/verifica_prorroga', data);
    if (response) {
      return response;
    }
  }
  async getBoxes(data) {
    let response = await Network.get('api/web/box', data);
    if (response) {
      return response;
    }
  }
  async PagarMantencion(data) {
    let response = await Network.get('api/pagoMantencion/pay', data);
    if (response) {
      return response;
    }
  }
  async getConf(data) {
    let response = await Network.get('api/web/configuracion', data);
    if (response) {
      return response;
    }
  }
  async getDataGrilla(data) {
    let response = await Network.get('api/cliente/grillaMiSendero', data);
    if (response) {
      return response;
    }
  }
  async PagarPieWebpay(data) {
    return await Network.post('api/pagoPieWebpay/pay', data);
  }
  async getContratosCCU(data) {
    let response = await Network.get('api/cliente/contratos_ccu', data);
    if (response) {
      return response;
    }
  }
  async getGrid(data) {
    let response = await Network.get('api/grilla/financiamiento', data);
    if (response) {
      return response;
    }
  }

  async getSlider(data) {
    let response = await Network.get('api/admin/slider', data);
    if (response) {
      return response;
    }
  }

  async getDetalle(data) {
    let response = await Network.get('api/detalle-compra/cuotas', data);
    if (response) {
      return response;
    }
  }
  async getDetalleEcommerce(data) {
    let response = await Network.get('api/detalle-compra/ecommerce', data);
    if (response) {
      return response;
    }
  }
  async getPosts(data) {
    let response = await Network.get('api/admin/posts', data);
    if (response) {
      return response;
    }
  }

  async Pagar(data) {
    let response = await Network.get('api/webpay/pay', data);
    if (response) {
      return response;
    }
  }

  async setBuyPlan(data) {
    let response = await Network.get('api/webpay/ecommerce/pay', data);
    if (response) {
      return response;
    }
  }

  async Preguntas(data) {
    let response = await Network.get('api/cliente/get/pregunta', data);
    if (response) {
      return response;
    }
  }
  async getFirmantes(data) {
    let response = await Network.get('api/obtener-firmantes', data);
    if (response) {
      return response;
    }
  }

  async verificaRut(data) {
    let response = await Network.get('api/cliente/login', data);
    if (response) {
      return response;
    }
  }
  async getPage(data) {
    let response = await Network.get('api/admin/page', data);
    if (response) {
      return response;
    }
  }
  async getOficinas(data) {
    let response = await Network.get('api/admin/page/oficinas', data);
    if (response) {
      return response;
    }
  }
  async getReglamentos(data) {
    let response = await Network.get('api/admin/page/reglamentos', data);
    if (response) {
      return response;
    }
  }
  async getParque(data) {
    let response = await Network.get('api/admin/page/parque', data);
    if (response) {
      return response;
    }
  }
  async getPageCanalDenuncia(data) {
    let response = await Network.get('api/admin/page/canal', data);
    if (response) {
      return response;
    }
  }
  async getPageDelitos(data) {
    let response = await Network.get('api/admin/page/delito', data);
    if (response) {
      return response;
    }
  }
  async getPageTipoMantencion(data) {
    let response = await Network.get('api/admin/page/tipo-mantencion', data);
    if (response) {
      return response;
    }
  }
  async getPlanes(data) {
    let response = await Network.get('api/admin/page/planes', data);
    if (response) {
      return response;
    }
  }
  async getServicios(data) {
    let response = await Network.get('api/admin/page/servicios', data);
    if (response) {
      return response;
    }
  }
  async getPlanCrematorio(data) {
    let response = await Network.get('api/admin/page/plancrematorio', data);
    if (response) {
      return response;
    }
  }
  async getStockPlanes(data) {
    let response = await Network.get('api/stock_plan', data);
    if (response) {
      return response;
    }
  }
  async getServiciosCrematorio(data) {
    let response = await Network.get('api/admin/page/serviciocrematorio', data);
    if (response) {
      return response;
    }
  }
  async getAllProducts(data) {
    let response = await Network.get('api/web/all/products', data);
    if (response) {
      return response;
    }
  }
  async PagarRapido(data) {
    return await Network.post('api/mall/start/pago-rapido', data);
  }
  async PagarRapidoKhipu(data) {
    let response = await Network.post('payments/khipu/create', data);
    //console.log(response);

    if (response) {
      return response;
    }
  }
  async getDetailtsPayment(data) {
    let response = await Network.get('/payments/khipu/detail', data);
    if (response) {
      return response;
    }
  }
  async getDetailtsPaymentGeneric(data) {
    let response = await Network.get('api/mall/get/detail-buy', data);
    if (response) {
      return response;
    }
  }

  async GetMantenimiento() {
    let response = await Network.get('api/web/Mantenimiento', {});
    if (response) {
      return response;
    }
  }

  async sendEmailNotification(data) {
    return await Network.post('api/send/agentelibre', data);
  }

  async getDetailtsPaymentWebEcomGeneric(data) {
    let response = await Network.get('api/pago-web/get/detail-buy', data);
    if (response) {
      return response;
    }
  }

  async getProductByID(data) {
    let response = await Network.post('api/web/unique/product', data);
    if (response) {
      return response;
    }
  }

  async getProductByCode(data) {
    let response = await Network.post('api/web/code/product', data);
    if (response) {
      return response;
    }
  }
  
  async CotizarLeads(data) {  
    return await Network.post('api/send/cotizar_leads', data);
  }
}

export default new Api();
