import React from "react";
import "./TitleSection.scss";

const TitleSection = ({ title, subtitle, all_medida_class, st_medida_class }) => {
  return (
    <div className={`${all_medida_class} box-title-seccion`}>
      <h2>{title}</h2>
      {subtitle !== "" && <p className={st_medida_class}>{subtitle}</p>}
    </div>
  );
};

export default TitleSection;
