import React, { Component } from 'react';
class Monto extends Component {
	constructor(props) {
		super(props)
		this.state = {
			valor: this.props.valor,
		}
		this.onChange = this.onChange.bind(this);
		this.formatNumberToCurrency = this.formatNumberToCurrency.bind(this);
	}
	onChange(event) {
		const { value } = event.target;
		this.setState({
			valor: value
		});

	}
	formatNumberToCurrency(number) {
		// Convertir el número a formato de moneda chilena
		return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(number);
	}
	render() {
		const { correlaivo, contrato, InsertarValor, montoMaxInitial } = this.props;
		const { valor } = this.state;
		return (
			<>
				{true && (
						<>
							<input
								type="text"
								className="text-right"
								onBlur={(e) => { InsertarValor(e, correlaivo, contrato) }}
								value={this.formatNumberToCurrency(valor)}
								onKeyPress={(e) => {
									const keyCode = e.keyCode || e.which; // Obtener el código de la tecla
									if ((keyCode >= 48 && keyCode <= 57) || keyCode === 8) {
										return;
									} else {
										e.preventDefault(); // Prevenir la acción por defecto si no es una tecla permitida
									}
								}}
								onChange={(e) => {
									//this.onChange(e);
									console.log(e.target.value)
									let noFormat = e.target.value.replace(/\D/g, ""); // Eliminar los caracteres no numéricos
									let newValue = Math.max(0, noFormat);
									if (/^\d*$/.test(newValue) ||  newValue === "") {
										newValue = Math.max(0, newValue); // Si es un número válido o está vacío, se establece como 1 si es menor que 1
									} 

									if(newValue > montoMaxInitial) {
										newValue = montoMaxInitial; // Si el valor es mayor al monto máximo, se establece como el monto máximo
										//document.getElementById("mensaje").innerText = "El valor ingresado excede el máximo permitido.";
									} else {
										document.getElementById("mensaje").innerText = ""; // Limpiar el mensaje si el valor es válido
									}
									console.log('Imprimiendo nuevo valor');
									console.log(newValue)

									this.setState({
										valor: newValue
									});	
									InsertarValor({ ...e, target: { ...e.target, value: newValue }}, correlaivo, contrato,newValue);								
								}}
								min={1}
								step={1}
								max={montoMaxInitial}
							/>
							<div id="mensaje" style={{ color: "red" }}></div>
						</>
				)}

			</>
		)
		
	}
}
export default Monto;