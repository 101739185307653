import React, { Component } from 'react';
import { Col, Button, Form, FormGroup, Input, Alert, Tooltip } from 'reactstrap';
import Api from '../../../../service/Api';

class Contacto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: props.dataUser.usuario,
            rut: props.dataUser.rut,
            email: props.dataUser.correo,
            telefono: '',
            password: '',
            PasswordR: '',
            txtMsj: '',
            AlertClass: '',
            disabledButton: false,
            tooltipOpen: false,
            validaP1: true,
            validaP2: true,
            iscontrato: props.iscontrato == 1 ? 1 : 0
        }
        this.setForm = this.setForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    async setForm() {
        if(this.state.telefono.trim().length < 9 ) {
            this.setState({
                txtMsj: "El telefono debe tener 9 digitos",
                AlertClass: "alert-danger"
            });
            return false;
        }
        if (this.state.disabledButton) {
            let response = await Api.registrarUsuario(this.state);
            if (response.result === 'success') {
                this.setState({
                    txtMsj: "Su cuenta fue creada correctamente, en unos segundos será redireccionado al login",
                    AlertClass: "alert-success"
                });
                localStorage.setItem("iscontrato", this.state.iscontrato);
                setTimeout(() => {

                    if (this.state.iscontrato == 1) {
                        window.location.assign('/acepta-contrato/');
                    } else {
                        window.location.assign('/mi-sendero/');
                    }

                }, 4000);
            } else if (response.result === 'errorExists') {
                this.setState({
                    txtMsj: "Se encuentra creada una cuenta para este Rut",
                    AlertClass: "alert-danger"
                });
            }
        }
    }
    handleInputChange(event) {
        var regex = /^(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ]/;
        this.setState({
            txtMsj: ""
        });
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (name === 'password' && value !== '') {
            if (value.length < 8) {
                this.setState({
                    validaP1: false
                })
                return false;
            }
            if (regex.test(value)) {
                this.setState({
                    validaP1: true
                })
            } else {
                this.setState({
                    validaP1: false
                })
            }
        }
        if (name === 'PasswordR' && value !== '') {
            if (value.length < 8) {
                this.setState({
                    validaP2: false
                })
                return false;
            }
            if (regex.test(value)) {
                this.setState({
                    validaP2: true
                })
            } else {
                this.setState({
                    validaP2: false
                })
            }
            if (value !== this.state.password) {
                this.setState({
                    validaP1: false,
                    validaP2: false,
                    txtMsj: "Las contraseñas ingresadas no coinciden",
                    AlertClass: "alert-danger"
                })
            } else {
                this.setState({
                    validaP1: true,
                })
            }
        }
        if (this.state.email && this.state.telefono) {
            this.setState({
                disabledButton: true
            });
        } else {
            this.setState({
                disabledButton: false
            });
        }
    }
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }
    render() {
        const { tooltipOpen, validaP1, validaP2 } = this.state;
        return (
            <>
                <Form>
                    <FormGroup row>
                        <Col xs={12} sm={12}>
                            <Input type="text" name="nombre" id="nombre" placeholder="Nombre" onChange={this.handleInputChange} value={this.state.nombre || ''} disabled />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs={12} sm={12}>
                            <Input type="text" name="rut" id="rut" placeholder="RUT" onChange={this.handleInputChange} value={this.state.rut || ''} disabled />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs={12} sm={12}>
                            <Input type="email" name="email" id="email" placeholder="Email" onChange={this.handleInputChange} value={this.state.email || ''} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs={12} sm={12}>
                            <Input type="tel" name="telefono" id="telefono" maxLength={9} placeholder="Teléfono" onChange={this.handleInputChange}
                             onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } else {
                                if(event.currentTarget.value.trim().length > 9) {
                                  event.preventDefault();
                                }
                              } }}
                             value={this.state.telefono || ''} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs={12} sm={12}>
                            <Input type="password" name="password" id="password" placeholder="Ingrese su Clave" onChange={this.handleInputChange} value={this.state.password || ''} className={!validaP1 ? 'fail' : ''} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs={12} sm={12} className="text-right">
                            <p>Requísitos contraseña <i id="TooltipExample" onClick={this.toggle} className="fa fa-info-circle" style={{ 'color': '#94C01F', 'fontSize': 15 + 'px' }}></i></p>
                            <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={this.toggle} className="toll">
                                <p className="toll">La contraseña debe tener mínimo 6 caracteres y contener al menos, una letra mayúscula, una minúscula o un símbolo.</p>
                            </Tooltip>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs={12} sm={12}>
                            <Input type="password" name="PasswordR" id="PasswordR" placeholder="Repita su Clave" onChange={this.handleInputChange} value={this.state.PasswordR || ''} className={!validaP2 ? 'fail' : ''} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12} className="text-center">
                            {this.state.txtMsj !== "" ? <Alert className={'alert ' + this.state.AlertClass}>{this.state.txtMsj}</Alert> : ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12} className="text-center">
                            <Button className="btn btn-verde button btn-popup" onClick={this.setForm} disabled={!this.state.disabledButton}>Regístrarse</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </>
        );
    }
}
export default Contacto;