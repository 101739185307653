import React, { useState, useEffect } from 'react';
import Layout from '../Cliente/Layout/Layout';
import { Row, Col } from 'reactstrap';
import { getUser } from "../../services/Storage.Utils";
import Button from '../../components/Button/Button';
import Alert from '../../components/Alert/Alert';
import * as Api from "../../api";

const DatosPersonales = (props) => {
    const [msg, setMsg] = useState('');
    const [status, setStatus] = useState('');
    const [loader, setLoader] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [inputs, setInputs] = useState({
        rut: getUser().rut,
        nombre: getUser().nombre,
        email: getUser().email,
        telefono: getUser().telefono,
        celular: getUser().celular,
        direccion: getUser().direccion,
        password: '',
        password_confirm: '',
    });

    const { rut, nombre, email, telefono, celular, direccion, password, password_confirm } = inputs;

    useEffect(() => {

        const getAvatar = async () => {

            const response = await Api.GetAvatar(getUser().rut);
            setAvatar(response.avatar);
            console.log(avatar);
        }
        getAvatar();
    }, [])

    const handleFormSubmit = async (e) => {

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

        if (!emailRegex.test(inputs.email)) {
            setMsg('Debe Ingresar un Email Válido');
            setStatus('danger');
            return false;
        }

        if(inputs.telefono == "") {
            setMsg('El telefono no puede ser vacío.');
            setStatus('danger');
            return false;
        }

        if(inputs.telefono.trim().length  < 9) {
            setMsg('El telefono debe tener 9 digitos.');
            setStatus('danger');
            return false;
        }
        
        setLoader(true);
        const response = await Api.UpdatePerfil({
            rut: rut,
            nombre: nombre,
            email: email,
            telefono: telefono,
            celular: celular,
            direccion: direccion,
            password: password,
            password_confirm: password_confirm,
            avatar: avatar,
        })
        const { validations, result } = response;
        if (response.status === 'success') {
            setMsg(result.msg);
            setStatus(response.status);
            localStorage.setItem('user', JSON.stringify(result.data));
        } else {
            setMsg(result.msg);
            setStatus(response.status);
        }
        setLoader(false);
    };

    const handleInput = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    };
    const handleChangeFile = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (img) {
            setAvatar(img.target.result);
            console.log(img.target.result);
        };

        console.log(avatar);
    }
    const Title = ({ name }) => {
        return (
            <div className="content-title">
                {name}
                <div className="title-line"></div>
            </div>
        )
    }
    const soloNumeros = (e) => {
        var key = window.event ? e.which : e.keyCode;
        if (key < 48 || key > 57) {
            //Usando la definición del DOM level 2, "return" NO funciona.
            e.preventDefault();
            return false;
        } else {
            return true;
        }

    }
    return (
        <Layout>
            <div className="perfil">
                <div className="row mt-5">
                    <div className="col-8 offset-2 offset-md-4 col-md-4 text-center">
                        <div className="edit-perfil">
                            <div className="row">
                                <div className="col-12">
                                    <div className="content-avatar">
                                        <div className="circle">
                                            {typeof avatar !== 'undefined' && avatar !== '' && avatar !== null ? (
                                                <img className="profile-pic" src={avatar} alt="" />
                                            ) : (
                                                <i className="fa fa-user fa-5x"></i>
                                            )}
                                        </div>
                                        <div className="p-image">
                                            <i className="fa fa-camera upload-button"></i>
                                            <input className="file-upload" type="file" accept="image/*" onChange={e => handleChangeFile(e.target.files[0])} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Bienvenido</p>
                            <h2>{getUser().nombre}</h2>
                        </div>
                    </div>
                </div>
                <div className="edit-perfil-form">
                    <Row>
                        <Col sm="12">
                            <form onSubmit={handleFormSubmit}>
                                <Title name={'Datos Personales'} />
                                <div className="row">
                                    <div className="form-group col-12 col-md-6 input-group">
                                        <input type="text" className="form-control input-disabled" id="nombre" name="nombre" placeholder="Nombre" value={nombre} disabled />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i cclassNamelass="fas fa-ban"></i></span>
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 input-group">
                                        <input type="text" className="form-control input-disabled" id="rut" name="rut" placeholder="RUT" value={rut} disabled />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="far fa-id-card"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-md-6 input-group">
                                        <input type="text" className="form-control" id="email" name="email" placeholder="Email" value={email || ''} onChange={handleInput} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="far fa-envelope"></i></span>
                                        </div>
                                    </div>
                                    {/* <div className="form-group col-12 col-md-6 input-group">
                                        <input type="text" className="form-control" id="email_confirm" name="email_confirm" placeholder="Confirmar email" value={email || ''} onChange={handleInput} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="far fa-envelope"></i></span>
                                        </div>
                                    </div> */}
                                    <div className="form-group col-12 col-md-6 input-group">
                                        <input type="text" className="form-control" id="telefono"  name="telefono" placeholder="Teléfono" value={telefono || ''}  onChange={handleInput} maxLength={9} onKeyPress={ e => soloNumeros(e)} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-mobile-alt"></i></span>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="row">
                                     <div className="form-group col-12 col-md-6 input-group">
                                        <input type="number" className="form-control" id="celular" name="celular" placeholder="Celular" value={celular || ''} onChange={handleInput} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-mobile-alt"></i></span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="form-group col-12 col-md-6 input-group">
                                        <input type="text" className="form-control" id="direccion" name="direccion" placeholder="Dirección" value={direccion || ''} onChange={handleInput} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-map-marker-alt"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <Title name={'Cambiar Contraseña'} />
                                    <div className="row">
                                        <div className="form-group col-12 col-md-6 input-group">
                                            <input type="password" className="form-control" id="password" name="password" placeholder="Password" onChange={handleInput} />
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="fas fa-lock"></i></span>
                                            </div>
                                        </div>
                                        {/* <div className="form-group col-12 col-md-6 input-group">
                                            <input type="password" className="form-control" id="password_confirm" name="password_confirm" placeholder="Password Confirmar" onChange={handleInput} />
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="fas fa-lock"></i></span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <Alert msg={msg} status={status} />
                                <div className="form-group row">
                                    <div className="col-12 offset-md-4 col-md-4 text-center mt-3">
                                        <Button
                                            classes={'bt-ms _bt-green'}
                                            handleLogin={handleFormSubmit}
                                            loader={loader}
                                            name={'Guardar'}
                                        />
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    )
}


export default DatosPersonales;