import React, { Component } from 'react';

class Monto extends Component {
	constructor(props) {
		super(props)
		this.state = {
			valor: parseInt(this.props.valor),
		}
		//this.onChange = this.onChange.bind(this);
        this.onRecalcularMonto = this.onRecalcularMonto.bind(this);

	}
	onRecalcularMonto(detalle,valor) {
        console.log(detalle)
        var objeto = {};

        if (Array.isArray(detalle)) {
            detalle.forEach(todo => {
                objeto = [{
                    'NUMERO_CONTRATO': todo.NUMERO_CONTRATO,
                    'NUMERO_CUOTA': todo.NUMERO_CUOTA,
                    'CONCEPTO_RECAUDACION': todo.CONCEPTO_RECAUDACION,
                    'MONTO_CUOTA': parseInt(valor),
                    'NUMERO_DOCUMENTO': todo.NUMERO_DOCUMENTO,
                    'CUOTA_MOROSA': todo.CUOTA_MOROSA,
                    'CODIGO_COMERCIO': todo.CODIGO_COMERCIO
                }];
            });
        } else {
            objeto = [{
                'NUMERO_CONTRATO': detalle.NUMERO_CONTRATO,
                'NUMERO_CUOTA': detalle.NUMERO_CUOTA,
                'CONCEPTO_RECAUDACION': detalle.CONCEPTO_RECAUDACION,
                'MONTO_CUOTA': parseInt(valor),
                'NUMERO_DOCUMENTO': detalle.NUMERO_DOCUMENTO,
                'CUOTA_MOROSA': detalle.CUOTA_MOROSA,
                'CODIGO_COMERCIO': detalle.CODIGO_COMERCIO
            }];
        }

        
        
        //console.log(objeto)
        this.props.amountDetalle(parseInt(valor));
        this.props.onClick(false, objeto, valor, this.props.type);
        this.props.onClick(true, objeto, this.props.type);
        this.props.calculaMontoTotal();
        

    }

    componentDidMount() {
        //this.props.amountDetalle(this.state.valor);
        this.onRecalcularMonto(this.props.detalle,this.state.valor);
    }
	render() {
		const {  montoMaxInitial, detalle } = this.props;
		const { valor } = this.state;
		return (
			<>
				{true && (
						<>
							<input
								type="number"
								className="form-control text-center"
								onBlur={(e) => {  this.onRecalcularMonto(detalle,valor); }}
								value={valor}
								onKeyPress={(e) => {
									const keyCode = e.keyCode || e.which; // Obtener el código de la tecla
									if ((keyCode >= 48 && keyCode <= 57) || keyCode === 8) {
										return;
									} else {
										e.preventDefault(); // Prevenir la acción por defecto si no es una tecla permitida
									}
								}}
								onChange={(e) => {
									//this.onChange(e);
									// console.log(e.target.value)
									let newValue = Math.max(0, e.target.value);
                                    console.log(newValue)
									if (/^\d*$/.test(newValue) || newValue === "") {
										newValue = Math.max(0, newValue); // Si es un número válido o está vacío, se establece como 1 si es menor que 1
									} 

									if(newValue > montoMaxInitial) {
										newValue = montoMaxInitial; // Si el valor es mayor al monto máximo, se establece como el monto máximo
										//document.getElementById("mensaje").innerText = "El valor ingresado excede el máximo permitido.";
									} else {
										document.getElementById("mensaje").innerText = ""; // Limpiar el mensaje si el valor es válido
									}
                                    
                                    newValue = parseInt(newValue);
                                    //console.log(newValue)
									this.setState({
										valor: newValue
									});	
                                    this.onRecalcularMonto(detalle,newValue);
								}}
								min={1}
								step={1}
								max={montoMaxInitial}
							/>
							<div id="mensaje" style={{ color: "red" }}></div>
						</>
				)}

			</>
		)
	}
}
export default Monto;