import React from 'react'
import BannerEnMantenimiento from '../../../pago-rapido/Assets/Images/banner_en_mantenimiento.png';
import './Mantenimiento.scss';

const Mantenimiento = ({ message }) => {

    const style = {
        'background': "url(" +BannerEnMantenimiento +")" 
    }

    return (
        <>
            <div className="app-mantenimiento" style={style}>
                <div className="app-mantenimiento__difuminado">
                    <h1>Página en Mantenimiento</h1>
                    <div className="app-mantenimiento__description">{message}</div>
                </div>
            </div>
        </>
    )
}

export default Mantenimiento