import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { getUser } from "../../../services/Storage.Utils";
import Alert from "./../../Alert/Alert";
import Button from "../../../components/Button/Button";
import * as Api from "../../../api";

const ItemAgreement = ({
  producto,
  parque,
  contrato,
  fecha_contrato,
  hidden_button,
  detalle_contrato,
  capacidad,
}) => {
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [collapse, setCollapse] = useState(0);
  const [dat_collapse, setDatCollapse] = useState([{}]);
  const toggle = (e, tab) => {
    e.preventDefault();
    if (collapse !== tab) {
      setCollapse(tab);
      setLoader(true);
      handleGetContratos();
    } else {
      setCollapse(0);
    }
  };
  const handleGetContratos = async () => {
    setLoader(true);
    const response = await Api.GetContratoDigital({
      rut: getUser().rut,
      contrato: contrato,
    });
    const { status, validations, result } = response;
    if (status === "success") {
      setDatCollapse(result);
    } else {
      setMsg(validations.result.msg);
      setStatus(validations.status);
    }
    setLoader(false);
  };
  return (
    <div className="col-12 col-md-4 text-left">
      <div className="item-agreement">
        <div className="row">
          <div className="col-12 col-md-4 d-none d-md-block">
            <img
              src={"https://sendero.cl/static/media/img_familia.077b05aa.jpg"}
              className="item-agreement__img-product rounded-circle img-fluid"
              alt=""
            />
          </div>
          <div className="col-12 col-md-8 text-left">
            <div className="item-agreement__content">
              <p className="title">{parque}</p>
              <p>
                Contrato N°: <span className="blue">{contrato}</span>
              </p>
              <p>
                Capacidad: <span className="blue">{capacidad}</span>
              </p>
              <p>
                Fecha Contrato: <span className="blue">{fecha_contrato}</span>
              </p>
              <a href="" className="link-agreement">
                Conoce más productos de tu parque
              </a>
            </div>
          </div>
        </div>
        {!hidden_button && (
          <>
            {typeof dat_collapse !== "undefined" &&
              dat_collapse.map((item, index) => (
                <div key={index}>
                  <Collapse
                    isOpen={parseInt(collapse) === index + 1 ? true : false}
                  >
                    <Alert msg={msg} status={status} />
                    <div className="conent-link text-left">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-agreement"
                      >
                        {item.name}
                      </a>
                    </div>
                  </Collapse>
                </div>
              ))}
            <Button
              classes={"bt-ms _bt-simple-blue"}
              handleLogin={(e) => {
                toggle(e, 1);
              }}
              loader={loader}
              name={"Descargar contrato"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ItemAgreement;
