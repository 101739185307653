import React from "react";
import { Link } from "react-router-dom";

const ItemAgreement = ({
  producto,
  contrato,
  saldo_total,
  fecha_contrato,
  hidden_button,
  type,
  parque,
  aviso,
  pie,
}) => {
  console.log(aviso);
  
  const UrlBtn = ({ url_btn, name }) => {
    return (
      <Link
        to={{
          pathname: url_btn,
          state: { id: contrato },
        }}
        className="bt-ms _bt-simple-blue"
      >
        {name}
      </Link>
    );
  };
  return (
    <div className="item-agreement">
      <div className="row">
        <div className="col-3">
          <img
            src={"https://sendero.cl/static/media/img_familia.077b05aa.jpg"}
            className="item-agreement__img-product rounded-circle img-fluid"
            alt=""
          />
        </div>
        <div className="col-9 text-left">
          <div className="item-agreement__content">
            <p className="title">{producto}</p>
            <p className="title">{parque}</p>
            <p>
              Contrato N°: <span className="blue">{contrato}</span>
            </p>
            {/*<p>
              Cuota: <span className={classes.blue}>{cuotas}</span>
            </p>*/}
            {/* <p>Saldo: <span className="blue">${new Intl.NumberFormat("de-DE").format(pie?.vigente?.adicional?.saldo_precio  ?? 0)}</span></p> */}
            <p>Saldo: <span className="blue">${new Intl.NumberFormat("de-DE").format((pie?.vigente?.adicional?.saldo_precio  ?? (saldo_total ?? 0 )  ))}</span></p>
            <p>
              Fecha Contrato: <span className="blue">{pie?.vigente?.adicional?.fecha_contrato ?? fecha_contrato}</span>
            </p>
            <p className="aviso">
              {aviso ? "Tiene cuotas pendientes" : "Usted está al día"}
            </p>
          </div>
        </div>
      </div>
      {!hidden_button && (
        <div className="row">
          <div className="col-6">
            <Link to={`detalle/${contrato}`} className="bt-ms _bt-simple-blue">
              Ver Más
            </Link>
          </div>
          <div className="col-6">
            {type === "object" ? (
              <UrlBtn url_btn={"acepta-y-pagar-pie/"} name={"Ir a aceptar y pagar"} />
            ) : (
              <UrlBtn url_btn={"cartola-de-pago/"} name={"Ir a aceptar y pagar"} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemAgreement;
