import React, { Component } from 'react';

class Adicional extends Component {
    render() {
        const { codigo_producto, manzana, sector, fraccion, monto_producto, parque, producto, contrato } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="v-box__detail">
                            <h2 className="green">Parque</h2>
                            <p>{parque}</p>
                            <p>Codigo producto: <span>{codigo_producto}</span></p>
                            <p>Fracción: <span>{fraccion}</span></p>
                        </div>
                        <div className="v-box__detail">
                            <h2 className="blue">Detalle</h2>
                            <p>{producto}</p>
                            <p>Manzana: <span>{manzana}</span></p>
                            <p>Monto producto: <span className="blue">${new Intl.NumberFormat("de-DE").format(monto_producto)}</span></p>
                        </div>
                        <div className="v-box__detail">
                            <h2 className="gray">Descripción</h2>
                            <p>Contrato N°: <span>{contrato}</span></p>
                            <p>Sector: <span>{sector}</span></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Adicional;