import React , {useState} from 'react'
import Button from './Button';
import * as Api from "../../api";

const ButtonAcceptContrato =  ({ item , infcontrato}) => {
    
    const [actionresult,setActionResult] = useState({loader :  false,msg: '',class: '',codigoresult: -1});

    const handleAccpetContrato = async () => {
        
        setActionResult(actionresult => ({...actionresult,...{loader: !actionresult.loader, msg: ''}}));

        const data = await Api.ActionAcceptarContrato({
            'campana' : infcontrato.CIA,
            'parque' : infcontrato.PARQUE,
            'codigo' : infcontrato.SERIE,
            'numero' : infcontrato.CONTRATO 
         });

        // const data  = {
        //     status: 'success',
        //     result: 0
        // };

        if (typeof data !== 'undefined') {
            const {  status , result } = data;

            if (status === 'success') {
                
                if(result == 1) {
                    setActionResult(actionresult => ({...actionresult,...{msg: 'Proceso finalizado exitosamente.', class: 'badge badge-success' , codigoresult: result}}));
                    localStorage.setItem('update',1);

                   setTimeout(function(){   window.location.reload() }, 3000);
                  
                } else {
                    setActionResult(actionresult => ({...actionresult,...{loader: !actionresult.loader , msg: 'Ha occurrido un Error para aceptar el contrato.', class: 'badge badge-danger' , codigoresult: result}}));
                }
            } else {
                console.log(result);
                setActionResult(actionresult => ({...actionresult,...{loader: !actionresult.loader, msg: 'Ha occurrido un Error inesperado.', class: 'badge badge-danger' , codigoresult: -99}}));
            }
        } else {
            setActionResult(actionresult => ({...actionresult,...{loader: !actionresult.loader}}));
          }     

    }

    return (
        <><span className={actionresult.class}>{actionresult.msg}</span><br />
        {actionresult.codigoresult == 1 ? <>Se redireccionará en 3 segundos . . .</> : <></>}
        <Button loader={actionresult.loader} handleLogin={handleAccpetContrato} name="Aceptar Contrato" classes="bt-ms _bt-green" type="button" /></>
    )
}

export default ButtonAcceptContrato