import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "reactstrap";
import ItemLink from "./ItemLink.jsx";
import Phone from "./Phone.jsx";
import Logo from "./../Logo.jsx";
import SideBarLeft from "./Mobile/SideBar";
import LogoSendero from "./../../assets/images/logo/LogoSendero.png";

class MenuSendero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      menuOpen: true,
      dropdownOpen: false,
      linkCurrent: window.location.pathname.split("/")[1],
    };
    this.linkFunctionCurrent = this.linkFunctionCurrent.bind(this);
    this.hover = this.hover.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  hover() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  linkFunctionCurrent(e, link) {
    window.scrollTo(0, 0);
    this.setState({
      linkCurrent: link,
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleMenuClick() {
    this.setState({ menuOpen: false });
  }
  render() {
    const { menuMobile, menuHorizontal } = this.props;
    const { linkCurrent, menuOpen } = this.state;
    let claseMenuDesktop = " espacio-menu ";
    let claseLinkDesktop = " menu nav-link ";
    let claseLinkMobile = " text-menu-mobile menu-item ";

    return (
      <div className="main">
        <div className="d-none d-md-block">
          <div className={"menu"}>
            <div className="content">
              <Navbar bg="white" expand="lg">
                <Nav navbar>
                  <NavItem>
                    <Logo add_class={"header"} />
                  </NavItem>
                  {menuHorizontal.map((item, index) => (
                    <ItemLink
                      {...item}
                      claseMenu={claseMenuDesktop}
                      claseLink={claseLinkDesktop}
                      key={index}
                      origen={"desktop"}
                      key={index}
                      linkFunctionCurrent={this.linkFunctionCurrent}
                    />
                  ))}
                </Nav>
              </Navbar>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none top-mobile">
          <div id="Sidebar" className={"_mobile"}>
            <div className={"menu"}>
              <div className="content">
                <div className="menu-left">
                  <SideBarLeft
                    pageWrapId={"page-wrap"}
                    outerContainerId={"Sidebar"}
                    handleMenuClick={this.handleMenuClick}
                    menuOpen={menuOpen}
                    component={menuMobile.map((item, index) => (
                      <ItemLink
                        {...item}
                        claseMenu={""}
                        claseLink={claseLinkMobile}
                        key={index}
                        linkCurrent={linkCurrent}
                        linkFunctionCurrent={this.linkFunctionCurrent}
                        handleMenuClick={this.handleMenuClick}
                        origen="mobile"
                      />
                    ))}
                  />
                </div>
                <div id="page-wrap">
                  <Link to={"/"}>
                    <img
                      src={LogoSendero}
                      className="img img-logo-mobile"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuSendero;